// EXPERIMENTAL USAGE OF TYPESCRIPT DAO API
// Not used in production

import React from 'react' // eslint-disable-line no-use-before-define
import { Dao, DaoApi } from '../lib/dao'
import { DaoOptions, EngineInstance } from '../lib/dao/dao'

const WASM_PATH = `${process.env.GATSBY_CDN_BASE_URL}Hiberworld.wasm.gz`
const RUN_EXPERIMENT = false

let instance: EngineInstance

const DaoPage = () => {
  if (!RUN_EXPERIMENT) return (<div>Not available</div>)

  const launch = async () => {
    const options: DaoOptions = {
      wasmBinaryURL: WASM_PATH,
      wasmArguments: ['Debug.ShowBuildInfo=false']
    }
    const canvas = document.getElementById('canvas') as HTMLCanvasElement
    const dao = new Dao(canvas, options)
    instance = await dao.launch()
  }

  const clear = async () => {
    const api = new DaoApi(instance)
    const result = api.worldClear()
    console.log({ result })
  }

  return (
    <div>
      <canvas className="canvas" id="canvas"></canvas>
      <button onClick={ () => { launch() }}>Launch</button>
      <button onClick={ () => { clear() }}>Clear</button>
    </div>
  )
}

export default DaoPage
